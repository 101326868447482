import { FirebaseError } from 'firebase/app';
import {
  User,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  setPersistence,
  browserLocalPersistence,
  inMemoryPersistence,
  sendPasswordResetEmail as resetPasswordEmail,
  signInWithCustomToken,
  UserCredential,
} from 'firebase/auth';
import { DocumentReference, doc, getDoc } from 'firebase/firestore';
import { defineStore } from 'pinia';
import { useAppStore } from './app';
import { useRouter } from 'vue-router';
import { computed, readonly, ref, watch } from 'vue';

export const useAuthStore = defineStore('auth', () => {
  // state
  const user = ref(undefined as User | null | undefined);
  const initCalled = ref(false);
  const loading = ref(false);
  const loginError = ref('');
  const companyReference = ref(null as DocumentReference | null);
  const userAccountReference = ref(null as DocumentReference | null);
  const aesticoUserUUID = ref(null as string | null);

  // getters
  const isLoggedIn = computed(() => user.value !== undefined && user.value !== null);
  const currentCompanyUUID = computed(() => companyReference.value?.id);
  const loggedInEmail = computed(() => user.value?.email);

  const appStore = useAppStore();

  function init() {
    const auth = appStore.firebaseAuth;
    const app = appStore.firebaseApp;
    const firestore = appStore.firebaseFirestore;

    if (!auth || !app || !firestore) {
      throw new Error('Firebase not initialized');
    }

    if (initCalled.value) return;

    const router = useRouter();

    onAuthStateChanged(auth, async (usr) => {
      user.value = usr;
      if (user.value) {
        userAccountReference.value = doc(firestore, `UserAccounts/${user.value.uid}`);

        const docSnap = await getDoc(userAccountReference.value);

        companyReference.value = doc(firestore, `Companies/${docSnap?.data()?.ParentCompanyUUID}`);
        aesticoUserUUID.value = docSnap?.data()?.UUID;

        await router.push({
          path: '/authenticated',
          query: {
            // Redirect to the path that the user was trying to access before being redirected to login
            // If no redirect path was specified or redirect is again pointing to login, redirect to the root path
            redirect: (
              new URL(window.location.toString()).searchParams.get('redirect') ||
              window.location.pathname ||
              ''
            )
              .toString()
              .replace('/login', ''),
          },
        });
      } else {
        companyReference.value = null;
        userAccountReference.value = null;
        aesticoUserUUID.value = null;
      }
      loading.value = false;
    });

    initCalled.value = true;
  }

  async function checkAuth(): Promise<boolean> {
    init();
    if (user.value === undefined) {
      await new Promise((resolve) => {
        const unwatch = watch(user, (n) => {
          if (n !== undefined) {
            unwatch();
            resolve(null);
          }
        });
      });
    }
    return !!user.value;
  }

  async function login(email: string, password: string, rememberLogin: boolean): Promise<UserCredential> {
    if (!email || !password) throw new Error('Email and password are required');

    loading.value = true;
    if (!initCalled.value) init();
    const auth = appStore.firebaseAuth;
    if (!auth) throw new Error('Firebase not initialized');

    try {
      loginError.value = '';
      const user = await signInWithEmailAndPassword(auth, email, password);
      await setPersistence(auth, rememberLogin ? browserLocalPersistence : inMemoryPersistence);
      return user;
    } catch (error) {
      if (error instanceof FirebaseError) loginError.value = error.code;
      else loginError.value = 'Unknown error';
      throw error;
    } finally {
      loading.value = false;
    }
  }

  async function loginWithToken(token: string): Promise<UserCredential> {
    const auth = appStore.firebaseAuth;
    if (!auth) {
      throw new Error('Firebase not initialized');
    }

    return await signInWithCustomToken(auth, token);
  }

  function logout() {
    signOut(appStore.firebaseAuth!);
  }

  async function sendPasswordResetEmail(email: string) {
    const auth = appStore.firebaseAuth;
    if (!auth) {
      throw new Error('Firebase not initialized');
    }
    auth.languageCode = appStore.language ?? 'de';
    await resetPasswordEmail(appStore.firebaseAuth!, email, {
      url: window.location.origin,
    });
  }

  return {
    user: user,
    loading: readonly(loading),
    loginError: readonly(loginError),
    companyReference: companyReference,
    userAccountReference: userAccountReference,
    aesticoUserUUID,
    isLoggedIn,
    currentCompanyUUID,
    loggedInEmail,
    init,
    checkAuth,
    login,
    logout,
    sendPasswordResetEmail,
    loginWithToken,
  };
});
