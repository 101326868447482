import { createI18n } from 'vue-i18n';
import { de, fr } from 'vuetify/locale';
import messages from '@intlify/unplugin-vue-i18n/messages';

const v18nInstance = createI18n({
  legacy: false, // you must set `false`, to use Composition API
  availableLocales: ['de', 'fr'],
  locale: 'de', // set locale
  fallbackLocale: 'de', // set fallback locale,
  messages: {
    de: { $vuetify: de, ...messages!['de'] },
    fr: { $vuetify: fr, ...messages!['fr'] },
  },
  // Configuration options for v18n
});

export default v18nInstance;
