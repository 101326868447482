<template>
  <v-sonner position="bottom-center" />
  <v-app full-height :lang="appStore.language">
    <AppBar />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script setup lang="ts">
import { useAppStore } from './store/app';
import { useAuthStore } from './store/auth';
import AppBar from './components/AppBar.vue';
import { VSonner } from 'vuetify-sonner';
import { onMounted, watch } from 'vue';
import 'vuetify-sonner/style.css';
import './styles/main.scss';
import './styles/dragAndDrop.scss';

const appStore = useAppStore();
const authStore = useAuthStore();

onMounted(() => {
  appStore.init();
  authStore.init();
});
</script>
