/**
 * main.ts
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Components
import App from './App.vue';

// Composables
import { createApp } from 'vue';

// Polyfills
import 'array.prototype.tospliced';

// Plugins
import { registerPlugins } from '@/plugins';

const app = createApp(App);

registerPlugins(app);
app.mount('#app');

console.log('v' + import.meta.env.VITE_APP_VERSION + ' running in ' + process.env.NODE_ENV + ' mode');
