<template>
  <v-select
    v-model="appStore.language"
    hide-details
    density="compact"
    variant="outlined"
    :items="items"
    item-text="title"
    item-value="value"
  ></v-select>
</template>

<script setup lang="ts">
import { useAppStore } from '@/store/app';
import { computed } from 'vue';

const appStore = useAppStore();

const items = computed(() => [
  {
    title: 'Deutsch',
    value: 'de',
    props: {
      appendIcon: appStore.language === 'de' ? 'mdi-check' : '',
      color: appStore.language === 'de' ? 'secondary' : '',
    },
  },
  {
    title: 'Français',
    value: 'fr',
    props: {
      appendIcon: appStore.language === 'fr' ? 'mdi-check' : '',
      color: appStore.language === 'fr' ? 'secondary' : '',
    },
  },
]);
</script>
